import { EmailSubscriptions } from "./utils/models"

export const DEFAULT_MESSAGE = "Hey! " +
  "I’m gathering professional feedback from my peers to " +
  "help me learn my strengths and opportunities to improve." +
  "I would request you to please provide me your valuable feedback," +
  " it should only take a few minutes with this app."


export const LOGIN_API = `${ process.env.GATSBY_API_URL}/auth/login`


export const NOTICATIONS: Map<EmailSubscriptions, string> = new Map<EmailSubscriptions, string>([
  [EmailSubscriptions.NEW_FEEDBACK, "You’ve received new feedback"], 
  [EmailSubscriptions.FEEDBACK_REQUEST, "You’ve received a feedback request"],
  [EmailSubscriptions.THANKS, "You’ve received thanks from a peer"],
  [EmailSubscriptions.FEATURE_UPDATES, "Johari app and feature updates"],
  [EmailSubscriptions.REMINDERS, "Quarterly Strengths and weakeness report"],
  [EmailSubscriptions.REPORT, "Feedback Reminders"],
])
